.areaPathCard {
    background-color: #efefef;
    border-radius: 4px;
    flex-basis: 300px;
    overflow: hidden;
    padding: 20px;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    gap: 20px;
}

.name {
    font-size: 24px;
    font-weight: bold;
}

.leads {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    font-size: 14px;
    gap: 10px;
}

.roleContainer {
    /* flex-basis: 300px; */
}

.pods {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;    
}

.pod {
    background-color: rgb(199, 199, 199);
    border-radius: 4px;
    padding: 5px 10px;
    /* border: solid rgb(144, 144, 144) 1px; */
}

.services {
    font-size: 16px;
    line-height: 18px;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 10px;
}

.service {
    text-decoration: none;
    cursor: pointer;
}

.service:hover {
    text-decoration: underline;

}