.container {
    background-color: rgb(243, 243, 243);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 150px;
    padding: 20px;
    border-radius: 4px;
    gap: 10px;
    cursor: pointer;
    transform: scale(1, 1);
    transition: all 0.1s ease-out;
}
.container:hover {
    transform: scale(1.05, 1.05);
}
.container > * {
}

.profileImage {
    /* width: 90%; */
    /* border-radius: 65px; */
    /* overflow: hidden; */
    /* height: auto; */
    /* height: 50px; */
    /* width: auto; */
    width: 140px;
    height: 140px;
    border-radius: 70px;
    overflow: hidden;
}

.profileName {
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: pre-line;
    /* text-align: center; */
    font-size: 18px;
    font-weight: bold;
    line-height: 18px;
    margin-bottom: 5px;
}

.profileTitle {
    font-size: 14px;
}
