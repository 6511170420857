.valueStreamSelector {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

.selection {
    background-color: #efefef;
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    border-radius: 4px;
    cursor: pointer;
    opacity: 0.7;
}

.selection:hover {
    background-color: white;
    opacity: 1;
}

.selected {
    background-color: white;
    opacity: 1;
}