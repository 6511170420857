.circlesTile {
    cursor: pointer;
    /* width: 100%; */
    /* height: 100%; */
    box-sizing: border-box;
    /* background-color: greenyellow; */
    margin-top: 80px;
}

.title {

}

.largeLayout {
    font-size: 36px;
}

.smallLayout {
    font-size: 14px;
}