.passwordProtected {
    padding: 40px;
    box-sizing: border-box;
    color: white;
    background-color: rgb(21, 21, 21);
    height: 100vh;
    display: flex;
    gap: 40px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.form {
    padding: 80px;
    /* background-color: rgb(24, 24, 24); */
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}

.button {
    color: black;
    background-color: rgb(211, 211, 211);
    cursor: pointer;
    outline: none;
}

.button:hover {
    background-color: white;
}

.input {
    padding: 5px;
    font-size: 18px;
}