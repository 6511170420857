.tabMenu {
    display: flex;
    gap: 40px;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: flex-start;
    margin: 10px 20px 0px 20px;
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent the tabs from wrapping */
  }
  
  .logo {
      height: 30px;
      position: relative;
      top: 5px;
      flex-shrink: 0; /* Prevent the logo from shrinking */
  }
  
  .tabsContainer {
    flex-grow: 1; /* Allow tabs to take up remaining space */
    overflow-x: auto; /* Allow tabs to scroll horizontally */
    display: flex;
    overflow: scroll;
  }
  