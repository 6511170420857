.detailPanel {
    background-color: #e6e6e6;
    width: auto;
    padding: 20px;
    border-radius: 4px;
    font-size: 14px;
    max-width: 300px;
    /* width: 300px; */
    box-shadow: 1 1 10 10 black;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transform: translateX(0px);
    transition: all 0.2s ease-out;
}

.detailPanel:hover {
    transform: translateX(10px);
    background-color: white;
}

.detailPanel:hover .subtitle {
    text-decoration: underline;
}

.detailPanel > div:nth-child(1) {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
}

.subtitle {
    font-size: 18px;
    text-decoration: none;
}

.detailPanel > div:nth-child(4) {
    font-size: 14px;
    /* font-weight: bold; */
    margin-top: 10px;
}

.detailPanel > div:nth-child(5) {
    font-size: 14px;
    /* font-weight: bold; */
    margin-top: 0px;
}

.colorStrip {
    position: absolute; /* Position it relative to the parent div */
    top: 0; /* Align to the top of the div */
    right: 0; /* Align to the right of the div */
    width: 10px; /* Width of the rectangle */
    height: 100%; /* Full height of the div */
}

