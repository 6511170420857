.container {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 2px;
}

.step {
    transition: all 0.1s;
    width: 2px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    flex-grow: 1;
    cursor: pointer;
    transform: scaleY(1);
}

.step:hover {
    background-color: orchid;
    transform: scaleY(1.3);
}
