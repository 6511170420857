.sidebar {
    background-color: #e6e6e6;
    min-width: 250px;
    box-sizing: border-box;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.title {
    font-size: 24px;
    font-weight: bold;
}

.categories {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.category {
    background-color: gainsboro;
    font-size: 14px;
    box-sizing: border-box;
    padding: 10px 10px;
    cursor: pointer;
}

.category:hover {
    background-color: white !important;
}