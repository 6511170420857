.container {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 60px 40px;
    /* background-color: aquamarine; */
    box-sizing: border-box;
}

.header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
}

.header > div:nth-child(1) {
    font-size: 36px;
    font-weight: bold;
}

.header > div:nth-child(2) {
    font-size: 21px;
    background-color: rgb(38, 38, 38);
    flex-grow: 0;
    padding: 10px 20px;
    color: white;
    font-weight: bold;
    border-radius: 4px;
    margin-top: 20px;
}

.header > div:nth-child(3) {
    margin-top: 5px;
}

.roles {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.roleList {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.roleList > div:nth-child(1) {
    font-weight: bold;
    font-size: 18px;
}

.roleCollection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

.roleCollection > div {
    background-color: rgba(0, 0, 0, 0.05);
    padding: 3px 8px;
    color: black;
    border-radius: 4px;
    cursor: pointer;
}

.roleCollection > div:hover {
    background-color: white !important;
}
