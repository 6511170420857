.categoryPieTile {
    cursor: pointer;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.chart {
    width: 100%;
    height: 100%;
}   