.container {
    /* background-color: greenyellow; */
}

.header {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* white-space: pre-line; */
}

.title {
    font-size: 24px;
    font-weight: bold;
    position: sticky;
    top: 0px;
    background-color: #e2e2e2;
    /* background-color: green; */
    z-index: 100;
    margin-top: 60px;
    padding: 20px 40px 10px 40px;
    display: flex;
}

.responsibilities {
    display: flex;
    flex-direction: column;
    gap: 30px;
    position: relative;
    margin: 0px 40px;
}

.mainResponsibility {
    font-size: 16px;
    /* font-weight: bold; */
    font-style: italic;
}

.otherResponsibilities {
    margin-left: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-size: 14px;
}

.otherResponsibilities > div:before {
    content: "•";
    margin-right: -4px;
    position: relative;
    top: 0px;
    right: 10px;
}

.blockSection > div:nth-child(1) {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 18px;
}

.resources {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 10px;
}
