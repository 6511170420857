.container {
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 400px;
    height: 100%;
    background-color: #e2e2e2;
    z-index: 100;
    filter: drop-shadow(rgba(0, 0, 0, 0.05) 0px 0px 10px);
    padding: 0px;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    box-sizing: border-box;
    overflow: scroll;
    padding-bottom: 40px;
}

.childHolder {
    display: flex;
    gap: 0px;
    transform: translateX(0px);
    transition: transform 0.3s ease-in-out;
}

.page2 {
    transform: translateX(-400px);
}

.childHolder > * {
    min-width: 400px;
}

.visible {
    transform: translateX(0);
}

.closeButton {
    background-color: clear;
    /* width: 30px; */
    /* height: 30px; */
    position: absolute;
    top: 20px;
    right: 20px;
    cursor: pointer;
    opacity: 0.3;
    z-index: 1000;
}

.closeButton:hover {
    opacity: 1;
}

.backButton {
    background-color: clear;
    /* width: 30px; */
    /* height: 22px; */
    position: absolute;
    top: 24px;
    left: 20px;
    cursor: pointer;
    opacity: 0.3;
    z-index: 1000;
}

.backButton:hover {
    opacity: 1;
}
