.service {
    background-color: rgba(255, 255, 255, 0.5);
    padding: 5px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
}
.service:hover {
    background-color: rgb(255, 255, 255);
}

.tooltipContentDescription {
    font-size: 14px;
}
