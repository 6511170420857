.profileImage {
    background-color: rgb(128, 128, 128);
}

.defaultImage {
    display: block;
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    height: auto;
}

.slackImage {
    display: block;
    position: relative;
    top: 0px;
    left: 0px;
    width: 100%;
    height: auto;
}

.defaltSvg {
    width: 100%;
    height: auto;
    fill: rgb(239, 239, 239);
    transform: scale(1.3) translate(0, 10%);
    /* opacity: 0.8; */
}
