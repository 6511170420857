.container {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 60px 40px;
    /* background-color: aquamarine; */
    box-sizing: border-box;
}

.roles {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    font-size: 14px;
}

.roles > div {
    background-color: rgba(0, 0, 0, 0.05);
    padding: 3px 8px;
    color: black;
    border-radius: 4px;
    cursor: pointer;
}

.roles > div:hover {
    background-color: white !important;
}

.header {
    font-size: 36px;
    font-weight: bold;
}
