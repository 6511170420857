.grandTotalTile {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    /* width: 100%; */
    /* height: 100%; */
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 0px;
}

.total {
    font-weight: bold;
}

.total.small {
    font-size: 36px;
}

.total.medium {
    font-size: 72px;
}

.total.large {
    font-size: 128px;
}

.title {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
    
    font-weight: bold;
    text-align: center;
}

.title.small {
    font-size: 18px;
}

.title.medium {
    font-size: 24px;
}

.title.large {
    font-size: 36px;
}

/* .logo {
    height: 40px;
} */

.logo.small {
    height: 20px;
}

.logo.medium {
    height: 30px;
}

.logo.large {
    height: 40px;
}