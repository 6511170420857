.roleView {
    display: flex;
    gap: 10px;
    align-items: center;
    background-color: clear;
    cursor: pointer;
}

.roleView:hover .roleImage {
    transform: scale(1.2, 1.2);
}

.roleImage {
    border-radius: 20px;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.3);
    height: 40px;
    width: 40px;
    transform: scale(1, 1);
    transition: all 0.1s ease-out;
}
