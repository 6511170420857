.container {
    width: 160px;
    border-radius: 4px;
    overflow: hidden;
    padding: 10px;
    background-color: rgb(245, 245, 245);
    border: 4px solid rgb(245, 245, 245);
    cursor: pointer;
    box-sizing: border-box;
    transition: all 0.1s ease-in-out;
}

.container:hover {
    background-color: #202124;
    border: 4px solid #202124;
}

.selected {
    background-color: #202124;
    border: 4px solid #202124;
}

.dimmed {
    opacity: 0.5;
}

.selected > .label {
    color: white;
    font-weight: bold;
}

.container:hover .label {
    color: white;
    font-weight: bold;
}

.label {
    width: 100%;
    text-align: center;
    color: black;
    font-weight: normal;
}

.tooltip {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    z-index: 1000;
    filter: drop-shadow(rgba(0, 0, 0, 0.1) 0px 0px 10px);
}

.header {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.header > div:nth-child(1) {
    font-size: 18px;
    font-weight: bold;
}

.roleList {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.roleList > div:nth-child(1) {
    font-weight: bold;
}

.roleCollection {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 5px;
}

.roleCollection > div {
    background-color: rgba(0, 0, 0, 0.1);
    padding: 3px 4px;
    color: black;
    border-radius: 4px;
}
