.relatedProject {
    background-color: rgb(231, 231, 231);
    padding: 20px;
    border-radius: 4px;
    font-size: 14px;
    box-sizing: border-box;
    /* flex-grow: 1; */
    flex-basis: 350px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
}

.relatedProject:hover {
    background-color: white;
}

.name {
    font-size: 18px;
    line-height: 21px;
    font-weight: bold;
}

.description {
    font-size: 14px;
    line-height: 16px;
}

.tagContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    align-items: baseline;
    margin-top: 10px;
}

.tag {
    font-size: 14px;
    padding: 3px 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.tag:hover {
    opacity: 1 !important;
    background-color: white !important;
}