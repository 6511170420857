.container {
    display: flex;
    flex-direction: row;
    height: 100%;
}

.content {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
}

.filterBar {
    background-color: rgb(243, 243, 243);
    display: flex;
    flex-direction: column;
}

.filterContainer {
    display: flex;
    flex-direction: column;
    overflow: scroll;
    padding: 10px 40px 40px 40px;
    width: 260px;
}

.search {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 40px 40px 10px 40px;
}

.select {
    min-width: 200px;
}

.filterContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.filterMastHead {
    background-color: rgb(243, 243, 243);
}

.filterHeader {
    padding: 10px 40px 10px 40px;
}

.filterGroup {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.filterName {
    font-size: 18px;
    font-weight: bold;
}

.filter {
    transition: all 0.1s ease-out;
}
.filterValue {
    font-size: 14px;
    display: inline-block;
    margin-left: 3px;
    cursor: pointer;
}

.checkboxLabel {
    display: flex;
    align-items: flex-start;
}

.tileContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;

    padding: 20px;
    overflow: scroll;
}

.button {
    width: 100%;
}
.button:hover {
    background-color: rgb(219, 219, 219);
}

.svgIcon {
    height: 30px;
    width: auto;
}
