

.artifact {
    font-size: 14px;
    padding: 20px;
    background-color: rgb(231, 231, 231);
    flex-basis: 350px;
    box-sizing: border-box;
    /* cursor: pointer; */
    padding-bottom: 30px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 4px;
}

.details {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.name {
    font-size: 18px;
    line-height: 21px;
    font-weight: bold;
}

.attachments {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.attachment {
    cursor: pointer;
    text-decoration: none;
    font-size: 16px;
    display: flex;
    gap: 5px;
    align-items: center;
}

.description {
    font-size: 14px;
    line-height: 16px;
    margin-top: 5px;
}

.attachment:hover {
    text-decoration: underline;
}

.documentIcon {
    opacity: 0.3;
}

.attachment:hover > .documentIcon {
    opacity: 1;
}