.container {
    /* background-color: yellow; */
    /* height: 20px; */
    display: grid;
    grid-gap: 2px;
    /* grid-template-columns: 1fr 1fr; */
    grid-auto-flow: column; 
    grid-auto-columns: 1fr;
}

.block {
    /* background-color: rgb(167, 167, 167); */
    /* flex-grow: 1; */
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    text-overflow: ellipsis; 
    /* white-space: nowrap; */
    /* overflow: hidden; */
    box-sizing: border-box;
    text-align: center;
    min-width: 0px;
    cursor: pointer;
    opacity: 1.0;
}

.block:hover {
    background-color: white !important;
}

.vertLine {
    display: inline-block;
    width: 2px;
    min-width: 2px;
    max-width: 2px;
    background-color: black;
    height: 20px;
    position: relative;
    left: -2px;
    top: 0px;
    margin-right: 5px;
}

.eventTooltip {
    padding: 5px;
    display: flex;
    flex-direction: column;
    gap: 0px;
}

.eventTooltip > :nth-child(1) {
    font-size: 18px;
    font-weight: bold;
}

.eventTooltip > :nth-child(2) {
    font-size: 14px;
    font-weight: normal;
}