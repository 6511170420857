
.tile {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}

.tile.editing {
    background-color: rgba(255, 255, 255, 0.01);
    cursor: pointer;
}

.tile.editing:hover {
    background-color: rgba(255, 255, 255, 0.05);
}

.title {
    font-size: 36px;
    font-weight: bold;
    color:rgb(255, 255, 255);
    text-align: center;
    margin-bottom: 20px;
}

.buttonGroup {
    position: absolute;
    top: 0px;
    right: 0px;
}

.closeButton {
    
    display: block;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 0.3);
}

.closeButton:hover {
    color: rgba(255, 255, 255, 1);
}

.a {
    background-color: teal;
    
}

.b {
    background-color: deeppink;
    
}

.c {
    background-color: yellowgreen;
    
}