.container {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    height: 100%;
}

.filterContainer {
    box-sizing: border-box;
    background-color: #e5e5e5;
    height: 100%;
    overflow: scroll;
    min-width: 250px;
    max-width: 250px;
    padding: 52px 40px 40px 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.teamsContainer {
    display: flex;
    flex-direction: column;
    padding: 40px;
    gap: 40px;
    box-sizing: border-box;
    height: 100%;
    flex-grow: 1;
    overflow: scroll;
}

.teamRow {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 20px;
}

.teamRowTitle {
    font-size: 36px;
    font-weight: bold;
}

.projectCardsContainer {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.filterHeader {
    font-size: 24px;
    font-weight: bold;
}

.tagGroups {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.tagGroup {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.tagGroupTitle {
    font-size: 14px;
    font-weight: bold;
}

.tagContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
}

.tag {
    font-size: 14px;
    padding: 3px 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.tag:hover {
    opacity: 1 !important;
    background-color: white !important;
}

