.historicalGridTile {
    cursor: pointer;
    width: 80%;
    box-sizing: border-box;
    padding: 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 100px;
    margin-bottom: 40px;
}

.allChartsContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
}

.historicalTileContainer {
    width: 300px;
    height: 200px;
    text-align: center;
}

.chart {
    width: 100%;
    height: 100%;
}

.disclaimer {
    font-size: 14px;
    font-weight: normal;
    color: rgba(255, 255, 255, 0.7);
    width: 100%;
    text-align: left;
    margin-top: 20px;
}