.simpleDashboard {
    /* padding: 40px; */
    box-sizing: border-box;
    color: white;
    background-color: rgb(28 21 43);
    height: 100vh;
    /* width: 100%; */
    display: flex;
    /* gap: 40px; */
    flex-direction: column;
    justify-content: space-between;
    /* align-items: center; */
    /* overflow: scroll; */
    /* scroll-behavior: smooth; */
    /* height: 100%; */
}

.menu {
    flex-grow: 0;
    background-color: rgb(19 13 31);
}

.content {
    flex-grow: 1;
    padding: 60px 40px;
    /* text-align: center; */
    overflow: scroll;
    display: flex;
    flex-direction: column;
    /* justify-content: flex-start; */
    align-items: center;
    /* background-color: blue; */
}

.mapContent {
    flex-grow: 1;
}