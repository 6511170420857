.container {
    margin: 0px 40px 40px 40px;
    display: flex;
    flex-direction: column;
}

.header {
    /* background-color: pink; */
    position: sticky;
    top: 0px;
    background-color: #d5d5d5;
    z-index: 1000;
    padding-bottom: 20px;
}

.pi {
    margin-bottom: 0px;
    padding-bottom: 20px;
    padding-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    
}

.piHeader {
    font-size: 48px;
    font-weight: bold;
    line-height: 36px;
}

.piProgress {
    width: calc(100% - 350px);
}

.currentPiDates {
    font-size: 18px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}

.jiraOverviews {
    display: flex;
    flex-direction: column;
    gap: 80px;
    padding-top: 20px;
    padding-bottom: 40px;
}

.statusCategoryContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.statusCategoryCollection {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 30px;
    width: calc(100% - 350px);
    position: relative;
    right: 0px;
}

.statusCategory {
    display: flex;
    align-items: flex-end;
    gap: 5px;
    font-size: 12px;
    font-weight: normal;
}

.colorBlock {
    width: 30px;
    height: 14px;
}
