.container {
    display: flex;
    flex-direction: column;
}

.title {
    text-decoration: none;
    cursor: pointer;
}

.title:hover {
    text-decoration: underline;
}

.subtitle {
    display: flex;
    font-size: 14px;
    gap: 20px;
}