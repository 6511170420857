.container {
    /* background-color: paleturquoise; */
    display: flex;
    flex-direction: column;
    gap: 5px;
    box-sizing: border-box;
    padding: 20px 20px;
    border-radius: 4px;
}

.current {
    background-color: rgba(255, 255, 255, 0.3);
}

.title {
    font-size: 24px;
    font-weight: bold;
    /* margin-bottom: 10px; */
}

.dateRange {
    /* background-color: thistle; */
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    font-weight: normal;
    /* display: inline; */
    margin-top: -5px;
    margin-bottom: 10px;
}