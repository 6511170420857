.historicalCountTile {
    cursor: pointer;
    width: 300px;
    height: 200px;
    box-sizing: border-box;
    padding: 0px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* background-color: green; */
}

.title {
    font-size: 21px;
    font-weight: bold;
}

.chart {
    width: 100%;
    height: 100%;
}