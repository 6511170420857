.percentConnectedTile {
    cursor: pointer;
    width: 80%;
    box-sizing: border-box;
    padding: 0px;
    
}

.pieCharts {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.pieContainer {
    flex-basis: 200px;
    /* width: 100px; */
    height: 200px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 20px;

}

.chart {
    width: 100%;
    height: 100%;
    /* background-color: green; */
}