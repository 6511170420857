.resourceView {
    display: inline-flex;
    gap: 10px;
    align-items: center;
    background-color: clear;
    cursor: pointer;
}
.resourceView:hover .resourceImage {
    transform: scale(1.2, 1.2);
}

.resourceImage {
    border-radius: 20px;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.3);
    height: 40px;
    width: 40px;
    transform: scale(1, 1);
    transition: all 0.1s ease-out;
}
