.pill {
    font-size: 8px;
    font-weight: bold;
    border-radius: 4px;
    display: block;
    padding: 2px 5px;
    margin-right: 0px;
    margin-top: 0px;
    box-sizing: border-box;
    color: white;
}
