.container {
    background-color: clear;
}

.showAllRoles {
    display: block;
    position: absolute;
    top: 20px;
    left: 20px;
    border-radius: 40px;
    padding: 10px;
    width: 40px;
    height: 40px;
    background-color: rgb(238, 238, 238);
    filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.1));
    display: flex;
    align-items: center;
    justify-content: left;
    cursor: pointer;
    z-index: 10;
    transition: all 0.2s ease-out;
    overflow: hidden;
}

.showAllRoles:hover {
    background-color: rgb(255, 255, 255);
    width: 180px;
}

.iconHolder {
    min-width: 40px;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0;
}

.iconTitle {
    flex-grow: 1;
    min-width: 200px;
    font-size: 18px;
    font-weight: normal;
    opacity: 0;
    transform: translateX(-60px) scale(0.5);
    transition: all 0.15s ease-out;
}

.showAllRoles:hover > .iconTitle {
    opacity: 1;
    transform: translateX(0px) scale(1);
}
