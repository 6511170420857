.container {
    /* background-color: pink; */
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100vh;
}

.topNav {
    background-color: #e6e6e6;
    display: flex;
    align-items: flex-end;
    padding-left: 40px;
    gap: 40px;
    flex-grow: 0;
    z-index: 1000;
    flex-grow: 0;
}

.content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}

.calendar {
    /* background-color: palevioletred; */
    z-index: 0;
    flex-grow: 1;
    overflow: scroll;
    height: 100%;
}

