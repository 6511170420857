.valueStreamDetails {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}

.title {
    font-size: 36px;
    font-weight: bold;
}

.description {
    font-size: 21px;
    line-height: 28px;
    margin-bottom: 10px;
}

.leads {
    display: flex;
    flex-direction: row;
    gap: 40px;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.roleContainer {
    /* flex-basis: 300px; */
}

.pods {
    /* margin-top: 40px; */
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}

.areaPaths {
    
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}

.sectionHeader {
    margin-top: 40px;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
}