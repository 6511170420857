.simplifiedValueStreams {
    box-sizing: border-box;
    height: 100%;
    overflow: hidden;
}

.platformsContainer {
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 80px;
    box-sizing: border-box;
    height: 100%;
    overflow: scroll;
}

.vsDetailsContainer {
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-sizing: border-box;
    height: 100%;
    overflow: scroll;
}

.platform {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    gap: 20px;
    flex-basis: 30%;
    flex-grow: 1;
    box-sizing: border-box;
    /* background-color: green; */
}



.valueStreams {
    margin-top: 40px;
    display: flex;
    gap: 20px;
    flex-direction: row;
    flex-wrap: wrap;
    box-sizing: border-box;
}

.valueStream {
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-sizing: border-box;
    padding: 20px;
    border-radius: 4px;
    background-color: #efefef;
    flex-basis: 300px;
    /* flex-grow: 1; */
    cursor: pointer;
}

.valueStream:hover {
    background-color: white;
}

.valueStreamTitle {
    font-size: 24px;
    font-weight: bold;
    min-width: 300px;
    box-sizing: border-box;
}

.valueStreamLeads {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-wrap: wrap;
    box-sizing: border-box;
    font-size: 14px;
}

.pods {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 0px;
    
}

.pod {
    background-color: rgb(199, 199, 199);
    border-radius: 4px;
    padding: 5px 10px;
    /* border: solid rgb(144, 144, 144) 1px; */
}