.container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    transition: all 0.3s ease-out;
}

.header {
    font-size: 36px;
    font-weight: bold;
    position: sticky;
    top: 168px;
    display: flex;
    flex-direction: column;
    padding-right: 20px;
    gap: 20px;
    flex-grow: 1;
}

.headerTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
}

.progressContainer {
    width: calc(100% - 350px);
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 16px;
}

.toggleButton {
    cursor: pointer;
    font-weight: normal;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    left: 9px;
    top: 5px;
}

.toggleButtonText {
    opacity: 0.0;
    transition: all 0.2s ease-out;
}
.toggleButton:hover > .toggleButtonText {
    opacity: 1.0;
}

.unfoldIcon {
    /* margin-left: 5px; */
    opacity: 0.3;
    /* height: 40px; */
    transition: all 0.2s ease-out;
}

.toggleButton:hover > .unfoldIcon {
    opacity: 1.0;
}

.details {
    font-size: 14px;
    font-weight: normal;
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-top: 10px;
}

.detailsSubHeader {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.epicSummary {
    display: flex;
    justify-content: space-between;
    width: 230px;
    font-size: 16px;
    align-items: center;
}

.percentage {
    font-size: 36px;
    font-weight: bold;
}

.pieChart {
    display: inline-block;
    width: 230px;
    height: 230px;
    /* background-color: lightslategray; */
    margin-top: -10px;
    position: relative;
    left: -10px;

}
