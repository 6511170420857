:root {
    --loader-color: rgb(135, 135, 135);
}

.container {
    width: 100%;
    height: 90%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 36px;
    font-weight: bold;
}

.message {
    /* background-color: greenyellow; */
    display: block;
}

.loader {
    color: #000000;
    display: inline-block;
    position: relative;
    box-sizing: border-box;
    margin-right: 50px;
}
.loader::after {
    content: "";
    width: 5px;
    height: 5px;
    background: currentColor;
    position: absolute;
    bottom: 10px;
    right: -8px;
    box-sizing: border-box;
    animation: animloader 1s linear infinite;
}

@keyframes animloader {
    0% {
        opacity: 0;
    }
    33% {
        opacity: 1;
        box-shadow: 10px 0 rgba(255, 255, 255, 0), 20px 0 rgba(255, 255, 255, 0);
    }
    66% {
        box-shadow: 10px 0 black, 20px 0 rgba(255, 255, 255, 0);
    }
    100% {
        box-shadow: 10px 0 black, 20px 0 black;
    }
}
