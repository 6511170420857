.podCard {
    background-color: #efefef;
    border-radius: 4px;
    flex-basis: 440px;
    overflow: hidden;
}

.line {
    height: 5px;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

.name {
    font-size: 24px;
    font-weight: bold;
}

.roles {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-bottom: 10px;
    font-size: 14px;
}

.support {
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-basis: 220px;
}

.devs {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 10px;
    flex-basis: 220px;
}