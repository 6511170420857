.researchProjectCard {
    background-color: #f3f3f3;
    padding: 20px;
    padding-bottom: 40px;
    border-radius: 4px;
    flex-basis: 400px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.researchProjectCard:hover {
    background-color: white;
}

.name {
    font-size: 24px;
    font-weight: bold;
    margin-top: 10px;
}

.description {
    font-size: 18px;
    line-height: 21px;
}

.question {
    font-size: 14px;
    font-style: italic;
}

.details {
    font-size: 14px;
    line-height: 16px;
}

.colorBarContainer {
    position: absolute;
    top: 0px;
    left: 0px;
    display: flex;
    flex-direction: row;
    width: 100%;
}

.colorBar {
    height: 5px;
    min-width: 10px;
    flex-grow: 1;
    background-color: deeppink;
}