html,
body {
    height: 100%;
    margin: 0;
    background-color: #d5d5d5;
}

.authenticating {
    display: flex;
    font-size: 36px;
    font-weight: bold;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    text-align: center;
}

.sign-in-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.sign-in-card {
    background-color: white;
    flex-grow: 0;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    font-size: 36px;
    font-weight: bold;
    border-radius: 4px;
    max-width: 300px;
}

.sign-in-button {
    background-color: rgba(0, 0, 0, 0.1);
}
.sign-in-button:hover {
    background-color: rgba(0, 0, 0, 0.05);
}
.sign-in-help-trigger {
    font-size: 14px;
    text-align: center;
    font-weight: normal;
    color: gray;
    cursor: pointer;
    text-decoration: none;
}
.sign-in-help-trigger:hover {
    color: dodgerblue;
    text-decoration: underline;
}

.sign-in-help {
    font-size: 14px;
    font-weight: normal;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.help-link {
    text-decoration: underline;
    color: dodgerblue;
}

.App {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    background-color: #d5d5d5;
}

.home {
    margin: 60px;
    font-size: 48px;
    line-height: 48px;
    font-weight: bold;
}

.home div {
    margin-bottom: 100px;
}

.sidebar {
    flex-grow: 0;
    background-color: #202124;
    /* width: 100px; */
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 1px;
    padding-top: 20px;
    min-width: 60px;
    max-width: 60px;
    transition: all 0.3s ease-in-out;
}
.sidebar:hover {
    min-width: 260px;
    max-width: 260px;
}

.sidebar a {
    text-decoration: none;
    color: white;
}

.sidebar a:hover {
    text-decoration: none;
    color: white;
}

.sidebar-

.sidebar-svg {
    width: 20px;
    height: auto;
    fill: white;
    opacity: 0.8;
}

.sidebar-icon-container {
    margin-bottom: 60px;
}

.foundry-icon-square {
    width: 40px;
    height: auto;
    transform: translate(0px, 0px) scale(1, 1);
    transition: all 0.3s ease-in-out;
    opacity: 1;
}
.sidebar:hover .foundry-icon-square {
    transform: translate(0px, 50px) scale(0, 0);
    opacity: 0;
}

.foundry-icon-rect {
    width: 237px;
    height: auto;
    transform: translate(-50px, -25px) scale(0.5, 0.5);
    display: block;
    position: absolute;
    top: 20px;
    left: 7px;
    transition: all 0.3s ease-in-out;
    opacity: 0;
}
.sidebar:hover .foundry-icon-rect {
    transform: translate(0px, 0px) scale(1, 1);
    opacity: 1;
}

.sidebar-button {
    /* background-color: rgb(9, 86, 86); */
    padding: 20px 20px;
    cursor: pointer;
    display: flex;
    flex: row;
    flex-wrap: nowrap;
    gap: 20px;
    color: white;
    font-size: 18px;
    align-items: center;
}
.sidebar-button:hover {
    background-color: rgba(255, 255, 255, 0.05);
}
.sidebar-button:active {
    background-color: rgba(255, 255, 255, 0.2);
}
.sidebar-button.selected {
    background-color: rgba(255, 255, 255, 0.1);
}
.sidebar-button > * {
    white-space: nowrap;
}

.sidebar-text {
    /* background-color: deeppink; */
    transform: translateX(20px);
    transition: all 0.3s ease-in-out;
    opacity: 0;
    text-align: left;
}
.sidebar:hover .sidebar-text {
    transform: translateX(0px);
    opacity: 1;
}
.sidebar-okta-username {
    font-size: 12px;
}

.top-bar {
    background-color: rgb(20, 25, 40);
    padding: 20px 20px 20px 20px;
    /* height: 40px; */
    gap: 20px;
    color: white;
    display: flex;
}
.top-bar > div {
    font-size: 18px;
}
.menu-logo {
    height: 30px;
}
.menu-icon {
    width: 20px;
}

.content {
    flex-grow: 1;
    /* background-color: rgb(255, 196, 0); */
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: scroll;
    /* background-color: #ced4d7; */
}

.constructionBanner {
    background-color: rgb(160, 1, 1);
    color: white;
    text-align: left;
    font-weight: bold;
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 9999999;
    width: 100%;
    box-sizing: border-box;
    padding: 40px;
}

.closeButton {
    position: absolute;
    right: 0px;
    top: 0px;
    background-color: clear;
    padding: 5px 10px;
    font-size: 24px;
    cursor: pointer;
}

.org-chart {
    /* background-color: yellow; */
    width: 100%;
    flex-grow: 1;
}

.d3-container {
    height: 100%;
    width: 100%;
}

.svg-chart-container {
    height: 100%;
    width: 100%;
}

/* canvas {
    width: 100%;
    height: 100%;
} */
.org-chart-node-container {
    width: 100%;
    height: 100%;
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    padding: 10px;
    box-sizing: border-box;
    border: 0px solid rgb(173, 173, 173);
    color: rgb(0, 0, 0);
}

.org-chart-node-resource {
    width: 100%;
    height: 100%;
    /* background-color: pink; */
    /* border-radius: 4px; */
    /* padding: 10px; */
    box-sizing: border-box;
    /* border: 1px solid rgb(173, 173, 173); */
    color: rgb(0, 0, 0);
}
.org-chart-node-resource-content {
    margin: -30px 0px 0px 0px;
    padding: 32px 10px 0px 10px;
    background-color: rgb(255, 255, 255);
    height: calc(100% - 60px);
    border: 0px solid rgb(173, 173, 173);
    border-radius: 4px;
}

.org-chart-node-button {
    border-radius: 4px;
    padding: 5px;
    font-size: 10px;
    margin: auto auto;
    background-color: rgb(231, 231, 231);
    border: 0px solid rgb(196, 196, 196);
    color: rgb(53, 53, 53);
}

.org-chart-node-image-container {
    width: 100%;
    height: 50px;
    /* background-color: deeppink; */
    margin: 0px 0px 5px 0px;
    display: flex;
    justify-content: center;
}

.org-chart-node-image {
    height: 100%;
    object-fit: scale-down;
    border-radius: 50px;
    border: 0px solid rgb(0, 0, 0);
    background-color: gray;
}

#org-chart-menu {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* background-color: deeppink; */
    gap: 20px;
}

.org-chart-menu-toggle {
    display: flex;
    justify-content: right;
}
.org-chart-menu-content {
    display: flex;
    flex-direction: column;
}
.org-chart-menu-content.hidden {
    display: none;
}

button {
    border-radius: 5px;
    padding: 10px;
    margin: 1px;
    /* outline: none; */
    border: none;
    cursor: pointer;

    background-color: rgba(255, 255, 255, 0.3);
    color: #1f282d;
}
button:hover {
    background-color: rgba(255, 255, 255, 0.8);
}

.org-chart-menu-button {
    background-color: rgba(255, 255, 255, 0.3);
    color: #1f282d6e;
    padding: 10px 20px 10px 20px;
    font-size: 12px;
    font-weight: bold;
    cursor: pointer;
    border-radius: 5px;
}
.org-chart-menu-button:hover {
    background-color: rgba(255, 255, 255, 0.6);
    color: #1f282d;
}
.org-chart-menu-button.selected {
    color: #1f282d;
}

.org-chart-title {
    position: absolute;
    display: block;
    top: 20px;
    left: 20px;
    font-size: 36px;
    font-weight: bold;
    color: rgb(0, 0, 0);
}
