.container {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    /* background-color: blueviolet; */
}

.sidePanel {
    /* background-color: rgba(0, 0, 0, 0.05); */
    /* background-color: pink; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.content {
    /* background-color: deeppink; */
    flex-grow: 1;
}
.backHover {
    padding: 10px;
    background-color: white;
    font-size: 14px;
    font-weight: bold;
}

.dataInfo {
    margin: 40px 0px 40px 40px;
    width: 300px;
    font-size: 14px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.colorKey {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.tShirtKeyTitle {
    font-size: 18px;
    font-weight: bold;
    font-style: normal;
}

.tShirtKey {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.tShirtSize {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.colorBlock {
    width: 30px;
    height: 30px;
    display: inline-block;
    border-radius: 4px;
}

.storyPointKey {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    /* font-style: normal; */
    /* font-weight: normal; */
}
