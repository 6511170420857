.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.topNav {
    background-color: #e6e6e6;
    display: flex;
    align-items: flex-end;
    padding-left: 40px;
    gap: 40px;
    flex-grow: 0;
}

.content {
    flex-grow: 1;
    height: 100%;
    overflow: scroll;
}
