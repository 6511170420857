.container {
    position: absolute;
    top: 0px;
    left: 0px;
    /* background-color: paleturquoise; */
    width: 100%;
    height: 100%;
    z-index: 10000;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
}

.bg {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: var(--bg-color);
    opacity: 0.8;
    cursor: pointer;
}

.content {
    z-index: 10000;
    background-color: var(--vs-bg-color);
    color: var(--vs-color);
    position: relative;
    padding: 40px 40px 40px 40px;
    border-radius: 4px;
    /* background-color: greenyellow; */
    filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.1));
    max-height: 80%;
    overflow: scroll;
}

.closeButton {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 30px;
    height: auto;
    cursor: pointer;
    opacity: 0.4;
}
.closeButton:hover {
    opacity: 1;
}

.closeSvg {
    width: 100%;
    height: auto;
    fill: var(--vs-color);
}
