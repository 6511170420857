
.projectYarn {
    padding: 40px;
    box-sizing: border-box;
    color: white;
    background-color: rgb(19 13 31);
    height: 100vh;
    display: flex;
    gap: 40px;
    flex-direction: column;
    align-items: flex-start;
    overflow: scroll;
    scroll-behavior: smooth;
}

.button {
    color: black;
    background-color: rgb(211, 211, 211);
    cursor: pointer;
    position: absolute;
    bottom: 40px;
}

.button:hover {
    background-color: white;
}

.loading {
    color: white;
    background-color: rgb(19 13 31);
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: bold;
}
