.projectDetails {
    margin: 40px 40px 40px 40px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* max-width: 800px; */
    font-size: 18px;
    overflow: scroll;
    height: 100%;
}

.header {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: baseline;
}

.backButton {
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
}

.backButton:hover {
    text-decoration: underline;
}

.name {
    font-size: 24px;
    font-weight: bold;
    margin-top: 20px;
}

.teams {
    font-size: 36px;
    font-weight: bold;
}

.overview {
    display: flex;
    gap: 20px;
}

.detailsContainer {
    font-size: 16px;
    line-height: 18px;
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin-bottom: 20px;
    margin-top: 10px;
}

.detailsBlock {
    display: flex;
    flex-direction: column;
    gap: 10px;
    box-sizing: border-box;
    min-width: 300px;
}

.description {
    font-size: 21px;
    line-height: 24px;
}

.question {
    font-size: 21px;
    line-height: 24px;
    font-style: italic;
    /* background-color: aliceblue; */
    /* padding: 40px; */
}

.artifacts {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.artifactViews {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    align-content: flex-start;
}

.tagContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    align-items: baseline;
    /* margin-top: 10px; */
    margin-bottom: 20px;
}

.tag {
    font-size: 14px;
    padding: 3px 8px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
}

.tag:hover {
    opacity: 1 !important;
    background-color: white !important;
}

.resourceContainer {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.relatedContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
}

.relatedProjects {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 10px;
}

