.container {
    position: relative;
    top: 0px;
    text-align: center;
}

.profileImage {
    height: 40px;
    width: 40px;
    border-radius: 30px;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transform: scale(1);
    transition: transform 0.1s ease-in-out;
}

.profileImage:hover {
    transform: scale(1.3);
}

.tagContainer {
    position: absolute;
    bottom: 0px;
    display: flex;
    justify-content: center;
    /* background-color: greenyellow; */
    width: 100%;
    transform: translateY(30%);
    pointer-events: none;
}
