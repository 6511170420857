.stackedAreaTile {
    cursor: pointer;
    width: 80%;
    min-height: 700px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.chart {
    width: 100%;
    height: 100%;
}

.disclaimer {
    font-size: 14px;
    font-weight: normal;
    color: rgba(255, 255, 255, 0.7);
    width: 100%;
    text-align: left;
    margin-top: 20px;
}