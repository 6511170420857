.tooltip {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 14px;
    margin: 5px;
}

.tooltipTitle {
    font-size: 18px;
    font-weight: bold;
}

.tooltipStatus {
    font-size: 12px;
}

.progressTitle {
    font-size: 18px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.progressComplete {
    font-size: 14px;
    /* font-weight: bold; */
    width: 250px;
    text-align: right;
}

.progressBarContainer {
    height: 30px;
}

.tableContainer {
    width: 100%;
    /* background-color: rgb(229, 229, 229); */
    transition: all 0.3s ease-out;
    overflow: hidden;
    position: relative;
}

.statusChip {
    height: 20px;
    width: 20px;
}

.closeButton {
    width: 40px;
    height: 40px;
    /* background-color: deeppink; */
    position: absolute;
    top: 12px;
    left: -40px;
    z-index: 1000;
    opacity: 0.2;
    cursor: pointer;
    display: block;
    /* float: right; */
    transition: all 0.2s ease-out;
}

.closeButton:hover {
    opacity: 0.5;
}

.issueLink {
    text-decoration: underline solid black 1px;
    cursor: pointer;
}

.issueLink:hover {
    text-decoration: underline solid black 2px;
}

.tableHeaderCell {
    /* cursor: pointer; */
    /* text-decoration: none; */
    color: rgb(76, 76, 76);
}

/* .tableHeaderCell:hover {
    text-decoration: underline;
    color: black;
} */
