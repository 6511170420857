.container {
    margin: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
}

.valueStream {
    display: flex;
    flex-direction: column;
    flex-basis: 45%;
    padding: 20px;
    gap: 20px;
    border-radius: 4px;
    background-color: rgb(229, 229, 229);
    flex-grow: 1;
}

.valueStreamHeader {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: flex-start;
}

.valueStreamTitle {
    font-size: 24px;
    font-weight: bold;
    cursor: pointer;
    text-decoration: none;
}

.valueStreamTitle:hover {
    text-decoration: underline;
}

.valueStreamSupport {
    display: flex;
    gap: 10px;
}

.areaPaths {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.areaPath {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
}

.areaPathTitle {
    font-size: 18px;
    cursor: pointer;
    flex-grow: 0;
    text-decoration: none;
}
.areaPathTitle:hover {
    text-decoration: underline;
}

.areaPathContent {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
    align-items: flex-start;
}

.areaPathSupport {
    display: flex;
    gap: 10px;
}

.services {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    flex-grow: 1;
}

.service {
    background-color: rgba(255, 255, 255, 0.5);
    padding: 5px;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
}
.service:hover {
    background-color: rgb(255, 255, 255);
}

.tooltipContentDescription {
    font-size: 14px;
}
