.container {
}

.button {
    background-color: clear;
    cursor: pointer;
    font-size: 18px;
    padding: 30px 0px 15px 0px;
    font-weight: bold;
    color: gray;
    transition: color 0.2s;
}

.button:hover {
    color: black;
}

.button.selected {
    color: black;
}

.bar {
    background-color: clear;
    height: 5px;
    width: 100%;
    position: relative;
    bottom: 0px;
    transition: background-color 0.2s;
}

.bar.selected {
    background-color: #737373;
}
