.container {
    background-color: rgba(255, 255, 255, 0.2);
    text-align: left;
    font-size: 24px;
    font-weight: bold;
    padding: 20px;
    height: 100%;
    width: 100%;
    /* transition: all 0.3s ease-in-out; */
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-radius: 4px;
}

.title {
    padding: 10px 10px;
    text-decoration: none;
}

.title:hover {
    /* text-decoration: underline; */
}

/* .title:hover::after {
    content: "click to show roles";
    color: rgb(87, 87, 87);
    text-decoration: none !important;
    margin-left: 10px;
    font-size: 14px;
    font-weight: normal;
} */

.selected {
    background-color: rgba(255, 255, 255, 0.3);
}

.container:hover {
    background-color: rgba(255, 255, 255, 0.3);
}

.roleContainer {
    margin: 110px 40px 0px 40px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.roleGroup {
    display: flex;
    flex-direction: column;
    gap: 30px;
    font-size: 14px;
    opacity: 0;
    transition: all 0.3s ease-in-out;
}

.selected .roleGroup {
    opacity: 1;
}

.key {
    display: flex;
    gap: 40px;
    font-size: 18px;

    font-weight: normal;
    margin-left: 5px;
}

.accountable:before {
    content: "";
    display: inline-block;
    width: 40px;
    height: 15px;
    background-color: black;
    margin-right: 10px;
    border-radius: 4px;
    opacity: 0.6;
}

.involved:before {
    content: "";
    display: inline-block;
    width: 40px;
    height: 15px;
    /* background-color: red; */
    margin-right: 10px;
    border-radius: 4px;
    background: repeating-linear-gradient(45deg, black 0px, black 6px, white 6px, white 8px);
    opacity: 0.3;
}

.roleBars {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.roleBar {
    height: 38px;
    width: 100%;
    transform: scaleX(1);
    transition: all 0.3s ease-in-out;
    transition-property: opacity, transform;
    font-weight: normal;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.02);

    border: 4px solid rgba(255, 255, 255, 0.01);
    box-sizing: border-box;
    transform: translate(0px, 0px);
    overflow: hidden;
}

.roleBar:hover {
    background-color: rgba(255, 255, 255, 0.3);
    border: 4px solid white;
    opacity: 1 !important;
    transition-duration: 0s;
}

.roleName {
    width: 100%;
    text-align: left;
    z-index: 100;
    box-sizing: border-box;
    padding: 0px 10px;
    transition: all 0.3s ease-in-out;
    transform: translateX(400px);
    opacity: 0;
}

.selected .roleName {
    transform: translateX(0px);
    opacity: 1;
}

.selected .roleBar {
    /* transform: scaleX(1); */
}

/* .hidden {
    transform: scaleX(0);
} */

.segment {
    transform: scaleX(0);
    transition: transform 0.3s ease-in-out;
    border-radius: 4px;
}

.selected .segment {
    transform: scaleX(1);
}

.expandButton {
    position: absolute;
    bottom: 5px;
    left: 0px;
    width: 100%;
    text-align: center;
    /* background-color: thistle; */
    opacity: 0;
    transform: rotate(0deg);
    transition: all 0.2s ease-in-out;
}

.container:hover .expandButton {
    opacity: 0.3;
    bottom: 0px;
}

.selected .expandButton {
    opacity: 0.3;
    bottom: 7px !important;
    transform: rotate(180deg);
}
