/* light theme */
:root {
    --bg-color: #d5d5d5;
    --bucket-color: rgb(255, 255, 255);
    --bucket-bg-color: #00458b;
    --vs-color: black;
    --vs-bg-color: #efefef;
    --vs-support-bg-color: #c4daca;
    --vs-pod-bg-color: #c4d3da;
    --vs-area-path-support-bg-color: rgb(223, 204, 182);
    --vs-tile-color: rgb(0, 0, 0);
    --vs-tile-header-bg-color: rgb(0 0 0 / 5%);
    --vs-tile-sub-header-bg-color: rgb(0 0 0 / 5%);
    --border-radius: 4px;
}

/* dark */
.theme2 {
    --bg-color: #242424;
    --bucket-color: white;
    --bucket-bg-color: #02366b;
    --vs-color: rgb(255, 255, 255);
    --vs-bg-color: #2e3139;
    --vs-support-bg-color: #304a45;
    --vs-pod-bg-color: #283a4f;
    --vs-area-path-support-bg-color: rgb(73 61 47);
    --vs-tile-color: rgb(255 255 255);
    --vs-tile-header-bg-color: rgb(255 255 255 / 10%);
    --vs-tile-sub-header-bg-color: rgb(255 255 255 / 5%);
}

/* dark hybrid */
.theme3 {
    --bg-color: #242424;
    --bucket-color: white;
    --bucket-bg-color: #00458b;
    --vs-color: rgb(255, 255, 255);
    --vs-bg-color: #3f444f;
    --vs-support-bg-color: #a1ccc1;
    --vs-pod-bg-color: #a1b9cc;
    --vs-area-path-support-bg-color: rgb(223, 204, 182);
    --vs-tile-color: rgb(0, 0, 0);
    --vs-tile-header-bg-color: rgba(255, 255, 255, 0.3);
    --vs-tile-sub-header-bg-color: rgba(255, 255, 255, 0.2);
}

/* flat */
.theme4 {
    --bg-color: #e1e1e1;
    --bucket-color: rgb(0 0 0);
    --bucket-bg-color: var(--bg-color);
    --vs-color: black;
    --vs-bg-color: var(--bg-color);
    --vs-support-bg-color: var(--bg-color);
    --vs-pod-bg-color: var(--bg-color);
    --vs-area-path-support-bg-color: var(--bg-color);
    --vs-tile-color: rgb(0, 0, 0);
    --vs-tile-header-bg-color: rgb(0 0 0 / 5%);
    --vs-tile-sub-header-bg-color: var(--bg-color);
    --border-radius: 4px;
}

/* colorful */
.theme5 {
    --bg-color: rgb(0 0 0);
    --bucket-color: white;
    --bucket-bg-color: #1a41cf;
    --vs-color: #faf8f8;
    --vs-bg-color: #2196f3;
    --vs-support-bg-color: #dc6ed1;
    --vs-pod-bg-color: #8cd83d;
    --vs-area-path-support-bg-color: rgb(255 187 51);
    --vs-tile-color: rgb(0, 0, 0);
    --vs-tile-header-bg-color: rgb(255 255 255 / 20%);
    --vs-tile-sub-header-bg-color: rgb(254 254 254 / 20%);
}
