.container {
    /* position: absolute; */
    width: 300px;
    /* left: 0px; */
    /* top: 0px; */
    margin: 40px 0px 40px 40px;
    z-index: 1000;
    display: block;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.dateSelector {
    background-color: #e6e6e6;
    width: auto;
    border-radius: 4px;
    font-size: 14px;
    max-width: 300px;
    /* width: 300px; */
    box-shadow: 1 1 10 10 black;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transform: translateX(0px);
    transition: all 0.2s ease-out;
}
