body {
    margin: 0;
    font-family: "GibsonCustom", "Helvetica Neue", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'GibsonCustom';  /* Define the name of your font */
    src: url('./fonts/Gibson/Gibson-Book.otf') format('opentype');  /* Path to your .otf file */
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'GibsonCustom';  /* Define the name of your font */
    src: url('./fonts/Gibson/Gibson-SemiBold.otf') format('opentype');  /* Path to your .otf file */
    font-weight: bold;
    font-style: normal;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
