.container {
    /* background-color: greenyellow; */
    /* padding: 40px; */
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.roleTitle {
    font-size: 36px;
    font-weight: bold;
}
.roleBreadcrumb {
    font-size: 18px;
}

.resourceDetails {
    /* background-color: greenyellow; */
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.slackIcon {
    width: 15px;
    height: auto;
}
.slackLink {
    display: flex;
    gap: 5px;
    font-size: 14px;
    align-items: center;
}

a {
    color: var(--vs-color);
    text-decoration: none;
}

a:hover {
    color: blue;
    text-decoration: underline;
}

.profileNameAndTitle {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.profileOverview {
    display: flex;
    gap: 30px;
}

.profileImage {
    overflow: hidden;
    width: 250px;
    height: 250px;
    border-radius: 125px;
    text-align: center;
    /* background-color: blueviolet; */
    align-self: center;
}

.profileDetails {
    /* background-color: darkmagenta; */
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.profileDetailsTable {
    font-size: 14px;
    /* background-color: aquamarine; */
    border-collapse: collapse;
}

.profileOrgHierarchy {
    /* background-color: cadetblue; */
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.profileOrgHierarchy > * {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.profileOrgHierarchyTitle {
    font-size: 14px;
    font-weight: bold;
}

.profileDirectReports {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.name {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: -5px;
}

.title {
    font-size: 18px;
    margin-bottom: 10px;
}

.propertyLabel {
    padding-right: 20px;
    padding-bottom: 5px;

    font-weight: bold;
    text-align: left;
    vertical-align: top;
}

.propertyValue {
    max-width: 250px;
}
