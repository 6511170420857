.platformHeader {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.topContainer {
    display: flex;
    width: 100%;
    box-sizing: border-box;
    justify-content: space-between;
    align-items: baseline;
}

.platformTitle {
    font-size: 48px;
    font-weight: bold;
    box-sizing: border-box;
}

.platformTitle.clickable {
    cursor: pointer;
    text-decoration: none;
}

.platformTitle.clickable:hover {
    text-decoration: underline;
}

.platformLeads {
    display: flex;
    flex-direction: row;
    gap: 20px 40px;
    flex-wrap: wrap;
    box-sizing: border-box;
    /* font-size: 16px; */
}

.backButton {
    font-size: 16px;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;
}

.backButton:hover {
    text-decoration: underline;
}